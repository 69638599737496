import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);


const routes = [
  {
    path: '/',
    name: 'Pealeht',
    component: () => import('../views/Pealeht.vue')
  },
  {
    path: '/asd',
    name: 'Sisselogimine',
    component: () => import('../views/Sisselogimine.vue')
  },
  {
    path: '/administraator',
    name: 'Administraator',
    component: () => import('../views/Administraator.vue'),
    beforeEnter: (to, from, next) => {
      if ( !localStorage.getItem("isAuthenticated")) {
        next('/sisselogimine')
      } else {
        next()
      }
    }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
