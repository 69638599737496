import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';

export const api_url = 'https://api.radimix.ee'


const refreshAuthLogic = failedRequest => axios.post(api_url + '/radimix/admin/refresh', {},
	{
		headers: {
		'Content-Type': 'application/json',
		'Authorization': 'Bearer ' + localStorage.getItem("refresh_token")
		}
	}).then(response => {
		sessionStorage.setItem("access_token", response.data.access_token)
	    failedRequest.response.config.headers['Authorization'] = 'Bearer ' + response.data.access_token;
	    return Promise.resolve();
	});

createAuthRefreshInterceptor(
	axios, 
	refreshAuthLogic,
	{
    	statusCodes: [ 401, 422 ] // default: [ 401 ]
	}
	);