import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators"
//import router from '@/router'
import { post, get } from 'axios'
import { api_url } from './radimix_endpoint'

//import radimix_login from './radimix_login'

@Module({name:'radimix_sisu'})
class radimix_sisu extends VuexModule {
	// 1 - loading, 2 - error, 3 - success
	sisu_state = 0
	sisu_message = ''

	pealdis = null
	info_kastid = null
	lingid = null
	teated = null

	pildid = null

	pealkiri = ''
	sisu = ''

	@Mutation
	set_sisu_state(payload) {
		this.sisu_state = payload;
	}
	
	@Mutation
	set_sisu_message(payload) {
		this.sisu_message = payload;
	}

	@Mutation
	set_info_kastid(payload) {
		this.info_kastid = payload;
	}

	@Mutation
	set_lingid(payload) {
		this.lingid = payload
	}

	@Mutation
	update_pealdis(payload) {
		this.pealdis = payload
	}

	@Mutation
	pildi_urlid(payload) {
		this.pildid = payload
	}

	@Mutation
	set_teated(payload) {
		this.teated = payload
	}

	@Action
	async get_pealdis() {
		this.context.commit('set_sisu_state', 1)

		await get(api_url + '/radimix/sisu/pealdis', {

		}).then(response => {
			this.context.commit('update_pealdis', response.data)
			this.context.commit('set_sisu_state', 3)

		})
	}

	@Action
	async set_pealdis(payload) {
		this.context.commit('set_sisu_state', 1)
		await post(api_url + '/radimix/sisu/pealdis/lisa', {
			pealkiri: payload.pealkiri,
			sisu: payload.sisu,
			pilt: payload.pilt,
			badge: payload.badge
		},{
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
			}
		}).then(response => {
			if ( response.data.status == "success") {
				this.context.commit('set_sisu_state', 3)
				this.context.commit('set_sisu_message', response.data.message)
			} else {
				this.context.commit('set_sisu_state', 2)
				this.context.commit('set_sisu_message', response.data.message)
			}
		})
	}

	@Action
	async get_info_kastid() {
		await get(api_url + '/radimix/sisu/info', {

		}).then(response => {
			this.context.commit('set_info_kastid', response.data)
		})
	}

	@Action
	async set_info(payload) {
		await post(api_url + '/radimix/sisu/info/lisamine', {
			info_tyyp: payload.info_tyyp,
			pealkiri: payload.pealkiri,
			sisu: payload.sisu,
			badge: payload.badge,
			pilt: payload.pilt
		},{
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
			}
		})
	}

	@Action
	async modify_info(payload) {
		await post(api_url + '/radimix/sisu/info/lisamine', {
			info_id: payload.info_id,
			info_tyyp: payload.info_tyyp,
			pealkiri: payload.pealkiri,
			sisu: payload.sisu,
			badge: payload.badge,
			pilt: payload.pilt
		},{
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
			}
		})
	}

	@Action
	async delete_info(info_id) {
		await post(api_url + '/radimix/sisu/info/kustutamine', {
			info_id: info_id
		},{
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
			}
		})
	}

	@Action
	async lae_pilt(file) {
		await post(api_url + '/radimix/sisu/pildi/lisamine',file,
		{
			headers: {
      		'Content-Type': 'multipart/form-data',
      		'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
    		}
    	}
		)
	}

	@Action
	async koik_pildid() {
		await get(api_url + '/radimix/sisu/pildid',{
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
			}
		}).then(response => {
			this.context.commit('pildi_urlid', response.data)
		},)
	}

	@Action
	async lingi_lisamine(payload) {
		await post(api_url + '/radimix/sisu/lingid/lisamine', {
			lingi_content_id: payload.lingi_content_id,
			lingi_pealkiri: payload.lingi_pealkiri,
			lingi_sisu: payload.lingi_sisu,
			lingi_cta: payload.lingi_cta,
			lingid_cta_color: payload.lingid_cta_color,
			lingid_cta_href: payload.lingid_cta_href
		},{
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
			}
		})
	}

	@Action
	async lingi_muutmine(payload) {
		await post(api_url + '/radimix/sisu/lingid/muuda', {
			lingi_id: payload.lingi_id,
			lingi_content_id: payload.lingi_content_id,
			lingi_pealkiri: payload.lingi_pealkiri,
			lingi_sisu: payload.lingi_sisu,
			lingi_cta: payload.lingi_cta,
			lingid_cta_color: payload.lingid_cta_color,
			lingid_cta_href: payload.lingid_cta_href
		},{
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
			}
		})
	}

	@Action
	async lingi_kustutamine(lingi_id) {
		await post(api_url + '/radimix/sisu/lingid/kustuta', {
			lingi_id: lingi_id
		},{
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
			}
		})
	}

	@Action
	async get_lingid() {
		await get(api_url + '/radimix/sisu/lingid', {
		}).then(response => {
			this.context.commit('set_lingid', response.data)
		})
	}

	@Action
	async get_teated() {
		await get(api_url + '/radimix/sisu/teated', {	
		}).then(response => {
			this.context.commit('set_teated', response.data)
		})
	}

	@Action
	async lisa_teade(teade) {
		await post(api_url + '/radimix/sisu/lisa/teade', {
			teade: teade
		},{
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
			}
		})
	}

	@Action
	async kustuta_teade(teate_id) {
		await post(api_url + '/radimix/sisu/kustuta/teade', {
			teate_id: teate_id
		},{
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
			}
		})
	}

}

export default radimix_sisu