import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators"
import { post, get } from 'axios'
import { api_url } from './radimix_endpoint'
import router from '@/router'

@Module({name: 'radimix_login'})
class radimix_login extends VuexModule {
	// 1 - loading, 2 - error, 3 - success
	login_state = 0
	login_message = ''
	is_authenticated = false

	opilane_reg_message = ''
	opilane_reg_state = 0

	kysimus_message = ''
	kysimus_staatus = 0

	opetajad = []
	opilased = []
	kysimused = []

	@Mutation
	set_login_state(payload) {
		this.login_state = payload;
	}
	
	@Mutation
	set_login_message(payload) {
		this.login_message = payload;
	}

	@Mutation
	set_opilane_reg_state(payload){
		this.opilane_reg_state = payload.opilane_reg_state
		this.opilane_reg_message = payload.opilane_reg_message
	}

	@Mutation
	set_kysimus_staatus(payload) {
		this.kysimus_message = payload.kysimus_message
		this.kysimus_staatus = payload.kysimus_staatus
	}

	@Mutation
	set_opetajad(payload) {
		this.opetajad = payload;
	}

	@Mutation
	set_opilased(payload) {
		this.opilased = payload;
	}

	@Mutation
	set_kysimused(payload) {
		this.kysimused = payload
	}

	@Mutation
	set_authentication(payload) {
		this.is_authenticated = payload;
	}

	@Action
	async login(login_data) {

		this.context.commit('set_login_state', 1);

		await post(api_url + '/radimix/admin/login', {
			kasutajanimi: login_data.kasutajanimi,
			parool: login_data.parool
		})
		.then(response => {
			if ( response.data.status == 'error' ) {
				this.context.commit('set_login_state', 2);
				this.context.commit('set_login_message', response.data.message)
			} else if ( response.data.status == 'success' ) {
				this.context.commit('set_login_state', 3);
				this.context.commit('set_login_message', response.data.message)

				sessionStorage.setItem("access_token", response.data.access_token)
				localStorage.setItem("refresh_token", response.data.refresh_token)
				localStorage.setItem("isAuthenticated", true)

			}
		})
	}

	@Action
	async logout() {
		sessionStorage.removeItem("access_token")
		localStorage.removeItem("refresh_token")

		localStorage.setItem("isAuthenticated", false)

		this.context.commit('set_login_state', 0)

		router.push("/sisselogimine")

	}

	@Action
	async register(register_data) {
		await post(api_url + '/radimix/admin/register', {
			kasutajanimi: register_data.kasutajanimi,
			eesnimi: register_data.eesnimi,
			perekonnanimi: register_data.perekonnanimi,
			parool: register_data.parool
		}, {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
			}
		})
	}

	@Action
	async delete(kasutajanimi) {
		console.log(kasutajanimi)
		await post(api_url + '/radimix/admin/delete', {
			kasutajanimi: kasutajanimi,
		}, {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
			}
		})
	}

	@Action
	async get_opetajad() {
		await get(api_url + '/radimix/admin/opetajad', {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
			}
		}).then(response => {
			this.context.commit('set_opetajad', response.data)
		})
	}

	@Action
	async reg_opilane(register_data) {
		await post(api_url + '/radimix/admin/opilane/registeeru', {
			email: register_data.email,
			eesnimi: register_data.eesnimi,
			perekonnanimi: register_data.perekonnanimi,
			isikukood: register_data.isikukood,
			address: register_data.address,
			telefon: register_data.telefoninumber,
			lisa_informatsioon: register_data.lisa
		}).then(response => {
				if ( response.data.status == 'error' ) {
					this.context.commit('set_opilane_reg_state', {
						opilane_reg_state: 2,
						opilane_reg_message: response.data.message
					})
				} else if ( response.data.status == 'success' ) {
					this.context.commit('set_opilane_reg_state', {
						opilane_reg_state: 3,
						opilane_reg_message: response.data.message
					})
				}
		})
	}

	@Action
	async get_reg_opilased() {
		await get(api_url + '/radimix/admin/opilased', {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
			}
		}).then(response => {
			this.context.commit('set_opilased', response.data)
		})
	}

	@Action
	async muuda_opilase_staatus(data) {
		await post(api_url + '/radimix/admin/opilane/muuda/staatus',{
			opilase_id: data.opilase_id,
			staatus: data.staatus
		}, {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
			}
		})
	}

	@Action
	async saada_kysimus(data) {
		await post(api_url + '/radimix/sisu/saada/kysimus',{
			email: data.email,
			telefon: data.telefon,
			kysimus: data.kysimus
		}).then(response => {
			if ( response.data.status == 'error' ) {
				this.context.commit('set_kysimus_staatus', {
					kysimus_staatus: 2,
					kysimus_message: response.data.message
				})
			} else if ( response.data.status == 'success' ) {
				this.context.commit('set_kysimus_staatus', {
					kysimus_staatus: 3,
					kysimus_message: response.data.message
				})
			}
		})
	}

	@Action 
	async vaata_kysimusi() {
		await get(api_url + '/radimix/sisu/vaata/kysimusi', {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
			}
		}).then(response => {
			this.context.commit('set_kysimused', response.data)
		})
	}

	@Action
	async arhiveeri_kysimus(id) {
		await post(api_url + '/radimix/sisu/arhiveeri/kysimus',{
			kysimuse_id: id,
		}, {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
			}
		})
	}
}

export default radimix_login