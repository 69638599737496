import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import Vuikit from 'vuikit'
import VuikitIcons from '@vuikit/icons'
import wysiwyg from "vue-wysiwyg";

import '@vuikit/theme'

Vue.use(Vuikit)
Vue.use(VuikitIcons)
Vue.use(wysiwyg, {}); 

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
