import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import radimix_login from './modules/radimix_login'
import radimix_sisu from './modules/radimix_sisu'
import radimix_token from './modules/radimix_token'

const store = new Vuex.Store({
	modules: {
		radimix_login,
		radimix_sisu,
		radimix_token
	}
})

export default store;
